@import "bulma/sass/utilities/initial-variables";
@import "bulma/sass/utilities/derived-variables";


// https://codepen.io/davidelrizzo/pen/GobVLe
// https://codepen.io/davidelrizzo/pen/eJwqzp
.timetable {
  // max-width: 70rem;

  display: grid;
  grid-template-columns: min-content repeat(5, minmax(0, 1fr));
  grid-template-rows: 2rem;
  grid-auto-rows: 3rem;
  column-gap: 0.15rem;

  @media screen and (min-width: $tablet) {
    column-gap: 1rem;
    grid-auto-rows: 3.5rem;
  }
}

.is-no-wrap {
  white-space: nowrap;
}

.is-clickable {
  cursor: pointer;
}

.cell {
  padding: 0.3rem 0.3rem;
  line-height: 1;
  font-size: 0.85rem;

  @media screen and (max-width: $tablet) {
    padding: 0.15rem;
    font-size: 0.75rem;
  }
  overflow: hidden;
  // white-space: nowrap;
  // text-overflow: ellipsis;

  &.col-time {
    border: 1px solid transparent;
  }
}

.hour {
  height: 100%;
  overflow-y: visible;
  background: linear-gradient(to top, $border-light 0px 1px, transparent 1px),
    linear-gradient(to bottom, $border-light 0px 1px, transparent 1px);
}

.editing {
  & .hour {
    cursor: pointer;
    &.empty:hover {
      background: linear-gradient(to top, $border 0px 2px, transparent 2px),
        linear-gradient(to bottom, $border 0px 2px, transparent 2px),
        linear-gradient(to left, $border 0px 2px, transparent 2px),
        linear-gradient(to right, $border 0px 2px, transparent 2px);
    }
  }
}

.highlighting .session:not(.highlighted) {
  opacity: 0.35;
}

.session {
  z-index: 2;
  // margin: 0 -0.25rem;

  & > * {
    // margin: 0 0.25rem;
  }

  // margin: 0 0.25rem;

  display: inline-block;
  position: relative;
  vertical-align: top;
  // float: left;

  // word-break: keep-all;
  
  height: 100%;

  overflow: hidden;
  // overflow-wrap: break-word;
  text-overflow: clip;
  // word-wrap: break-word;
  // white-space: nowrap;

  // padding-top: 0.5em;
  //line-height: 1;

  cursor: pointer;

  // margin-left: 2px;
  // margin-right: 2px ;
  // margin-right: -1px;

  border: 2px solid $border;
  // border-left-width: 10px;
  // border-right-width: 10px;
  // border-bottom-color: transparent;
  // margin: 1px;
  // box-sizing: border-box;

  border-radius: 0px;
  background-color: $white-bis;

  transition: opacity 200ms;
  
  // color: black;

  &.not-highlighted {
    color: $black !important;
    border-color: $border !important;
    background-color: #fafafa !important;
  }

  &.highlighted {
    // background-color: hsl(219, 70%, 96%) !important; //hsl(0; 0%; 99.9%);
    // border-color: $grey-dark;
    // overflow: visible;
    z-index: 10;
  }

  &.clash {
    // background: hsl(48, 100%, 96%);
  }

  &.empty {
    background-color: transparent;
    border-color: transparent;
    z-index: -1;
  }
}
