@import 'bulma/bulma';

// $border-light: #ededed;

.mdl-button.firebaseui-idp-anonymous { 
  margin-bottom: 0.75rem;
}

.bordered {
  box-shadow: none;
  border: 1px solid $border;
  border-radius: 4px;
}

.button-card {
  height: unset;
  text-align: left;
  line-height: 1.2;

  max-width: 100%;

  white-space: pre-wrap;
  justify-content: left;
}
