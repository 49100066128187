@import "bulma/sass/utilities/initial-variables";
@import "bulma/sass/utilities/derived-variables";

.session-selector {
  
  // background-color: transparent;
  
  & .message-header {
    // background-color: $white-ter;
    // color: $black;
    // border-bottom: 1px solid lightgrey;
    // flex-wrap: wrap;

    & label {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    & .buttons {
      flex-wrap: nowrap;
    }
  }

  & .message-body {
    // border-top-width: 1px;
    & .column {
      max-width: 10rem;
    }
  }

  border: 1px solid lightgrey;

  // background-color: transparent;
}
